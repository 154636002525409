import type { FeaturedEventRail } from '~/src/services/rails';
import { AhotuSection } from '~/src/templates/AhotuSection';

import FeaturedEvents from './FeaturedEvents';

interface FeaturedEventRailsProps {
  featuredEventRails: FeaturedEventRail[];
}

const FeaturedRails = ({ featuredEventRails }: FeaturedEventRailsProps) => {
  // TODO: Decouple the title ID and sorting; we otherwise force duplicate keys in i18nexus.
  // Ex: bestsellers could be sorted by net revenue or by number of tickets sold, but both would have the same title.
  return (
    <>
      {featuredEventRails.map(
        ({ id, events }) =>
          events?.length > 3 && (
            <AhotuSection key={id} layout="left-container" id={id as string}>
              <FeaturedEvents
                events={events}
                useOn="homepage"
                fromSection={`${id}_section`}
                titleKey={`common:${id}rail`}
              />
            </AhotuSection>
          )
      )}
    </>
  );
};

export default FeaturedRails;
