'use strict';
import Image from 'next/image';

import config from '../../../lib/config';
import AhotuUI, { RichTextRenderer } from '../../AhotuUI';
import { landingMaxWidths } from '../../organisms/LandingPageContent';

export const Intro = ({ image, imageAlt, header, subtext, textAboveLogo }: ContentIntro) => {
  return (
    <div className={`flex flex-col justify-center items-start ${landingMaxWidths}`}>
      <p className={`mb-2 ${AhotuUI.typography.body['b-12']}`}>{textAboveLogo}</p>
      <div className={`relative w-[167px] h-[31px] ${subtext && subtext !== '' ? 'mb-[56px]' : ''}`}>
        <Image src={`${config.CMS_ASSETS_URL}${image}`} layout="fill" alt={imageAlt} quality={90} />
      </div>
      {header && header !== '' && <h1 className={`mb-4 ${AhotuUI.typography.h1['h1-56']}`}>{header}</h1>}
      <div className={`${RichTextRenderer} whitespace-pre-wrap`}>
        <p>{subtext}</p>
      </div>
    </div>
  );
};
export type ContentIntro = {
  image: string;
  header: string;
  subtext: string;
  imageAlt: string;
  textAboveLogo: string;
};
