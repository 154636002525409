import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef } from 'react';

import { useBucketList } from '~/src/contexts/bucketListContext';
import { useSignInModal } from '~/src/contexts/SignInModalProvider';
import { useScrollRef } from '~/src/contexts/SignInModalProvider/ScrollRefProvider';
import { useUserContext } from '~/src/contexts/UserProvider/UserProvider';
import { toInt } from '~/src/lib/prismaUtils';
import { ADD_EVENT_ACTION } from '~/src/services/afterSignInAction';

import clsxm from '../../lib/clsxm';
import * as gtag from '../../lib/ga4/gtag';
import { Size } from '../../types/enums';
import { FeaturedEvent, FeaturedEventsPage } from '../../types/sportPageTypes';
import useMediaQuery from '../../utils/useMediaQuery';
import AhotuUI from '../AhotuUI';
import AhotuLink from '../atoms/AhotuLink';
import AhotwoProperty from '../atoms/AhotwoProperty';
import { CardPill } from '../atoms/CardPill';
import { Heading } from '../atoms/Heading';
import StravaIndicator from '../atoms/StravaIndicator';
import { BookmarkIcon, CalendarIcon, LocationIcon } from '../icons/icons';
import { StravaIcon } from '../icons/socialMedia';
import ReviewsHint from '../organisms/reviews/ReviewsHint';

type EventType = Omit<FeaturedEvent, 'id'> & { id: string | number };
interface IFeaturedCardProps {
  event: EventType;
  width?: number;
  isRecommended?: boolean;
  useOn?: FeaturedEventsPage;
  fromSection: string;
  idPrefix?: string;
}

const parseEventIdStringIntoNumber = (toParse: string | number): number =>
  typeof toParse === 'string' ? parseInt(toParse.split('_')[1]) : toParse; //Because some of the time, it's a string in the format event_1234, and other times just 1234! 8)))

export const FeaturedCard = ({
  event,
  width,
  isRecommended = false,
  useOn,
  fromSection,
  idPrefix = ''
}: IFeaturedCardProps) => {
  const { t } = useTranslation();
  const size = isRecommended ? Size.xs : Size.sm;
  const isMobile = useMediaQuery('md'); //FIXME: please stop using these they don't even seem to play nice with ssr

  const { bucketList, addToBucketList, removeFromBucketList } = useBucketList();
  const { user } = useUserContext();
  const { scrollRef, fromSectionRef, resetRefs } = useScrollRef();
  const { openSignInModal } = useSignInModal();

  const maxDiscount = event.maximumDiscount;

  //TODO: edge case - if you click to bookmark a non-bookmarked item, it changes its state eagerly to bookmarked. But since that state is internal to the Icon component, we have no way of forcing it to stay shown. IE: if you quickly move off from the bookmark then it will stop showing - then show again once the request completes.
  const isSelected: boolean = useMemo(
    () =>
      !!bucketList.find(
        b => toInt(b.a_event_id) === parseEventIdStringIntoNumber(parseEventIdStringIntoNumber(event.id))
      ),
    [bucketList, event]
  );

  const cardRef = useRef(null);

  useEffect(() => {
    if (scrollRef === parseEventIdStringIntoNumber(event.id) && fromSectionRef === fromSection) {
      setTimeout(() => {
        cardRef.current?.scrollIntoView();
        resetRefs();
      }, 0);
    }
  }, [scrollRef, resetRefs, event.id, fromSectionRef, fromSection]);

  const onClick = () => {
    if (isRecommended) {
      gtag.sendEventSelection(event as any, 'Recommended events'); //FIXME: type definition no longer matches what's expected, but EventType now actually reflects the real object. So, has this been broken the whole time or should it expect EventType?
      gtag.sendEvent('recommended_for_you', { event_id: event.permalink });
    } else {
      gtag.sendEventSelection(event as any, 'Featured events'); //FIXME: type definition no longer matches what's expected, but EventType now actually reflects the real object. So, has this been broken the whole time or should it expect EventType?
      gtag.sendEvent(useOn + '_featured_events', { event_id: event.permalink });
    }
  };

  return (
    <>
      <AhotuLink href={event.url}>
        <div
          ref={cardRef}
          id={`${idPrefix}${isRecommended ? 'recommended' : 'featured'}${event.url}`}
          onClick={onClick}
          className={clsxm(
            isRecommended ? 'text-xs' : 'text-sm',
            'h-fit snap-center cursor-pointer group/featuredcard relative'
          )}
          style={{ width: width }}>
          <div className={clsxm(isRecommended ? 'h-[119px]' : ' h-[188px]', 'relative')} style={{ width: width }}>
            <Image
              layout="fill"
              objectFit="cover"
              sizes="(max-width: 768px) 50vw, (max-width: 1280px) 33vw, (max-width: 1623px) 25vw, (max-width: 1800px) 20vw, 25vw"
              src={`https://images.ahotu.com/${event.titleImage.key}?w=1920&f=webp`}
              placeholder="blur"
              alt={`Image of ${event.name}`} //TODO: i18n!
              blurDataURL={event.titleImage.base64}
            />
            {maxDiscount > 0 && (
              <CardPill className={`absolute left-[8px] bottom-[0px] mb-2`}>
                <span className={`${AhotuUI.typography.body['b-12']} text-[#00ad6f] font-bold`}>
                  {t('common:discountText', { discount: Math.floor(maxDiscount) })}
                </span>
              </CardPill>
            )}
            {event.reviewsOverview?.total > 0 && (
              <ReviewsHint
                event={event as any}
                showTotal={false}
                className="absolute bg-white rounded-full px-[6px] py-1 left-2"
              />
            )}
            {event?.hasStravaItinerary && (
              <div>
                <StravaIndicator />
              </div>
            )}
            {event?.hasStravaItinerary && (
              <div>
                <div className="absolute bg-white rounded-full bottom-[0px] px-1 my-2 right-2">
                  <div className="flex items-end space-x-1 hover:cursor-pointer font-bold py-[3px]">
                    <StravaIcon fill="#FC5200" className="h-[18px] w-[18px] mr-[-5px]" />
                    <span className="text-xs lg:text-[.58rem]">{t('common:map')}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="h-[100%] flex flex-col justify-around">
            <Heading
              size="sm"
              headingLevel="h3"
              className={clsxm('my-3 font-bold', isMobile ? 'line-clamp-1' : 'h-10 line-clamp-2')}>
              {event.name}
            </Heading>
            <div className="grid gap-y-1">
              <AhotwoProperty size={size} icon={isRecommended && LocationIcon} onOneLine={true}>
                {event.location === 'anywhere' ? t('common:anywhere') : event.location}
              </AhotwoProperty>
              <AhotwoProperty size={size} icon={isRecommended && CalendarIcon} onOneLine={true}>
                {event.date}
              </AhotwoProperty>
              {event.sports && (
                <AhotwoProperty size={size} onOneLine={true}>
                  {event.sports}
                </AhotwoProperty>
              )}
              {event.price && ( //don't show the price until currency microservice is ready
                <AhotwoProperty size={size} className="mt-1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('common:from-price', { price: roundPrice(event.price) })
                    }}></span>
                </AhotwoProperty>
              )}
            </div>
          </div>
          {
            <div
              className={`absolute right-[8px] top-[0px] bg-white rounded-b-md ${
                isSelected ? '[@media(any-hover:hover)]:opacity-100' : '[@media(any-hover:hover)]:opacity-0'
              } group-hover/featuredcard:opacity-100 transition-opacity duration-150`}>
              <BookmarkIcon
                isSelectedProp={isSelected}
                addEventPromise={() => {
                  const eventId = parseEventIdStringIntoNumber(event.id);
                  if (!user) {
                    return new Promise((_, reject) => {
                      openSignInModal();
                      localStorage.setItem(
                        ADD_EVENT_ACTION,
                        JSON.stringify({
                          key: ADD_EVENT_ACTION,
                          // fromSection: distinguished from 2 events appearing on the same page
                          payload: { eventId, fromSection }
                        })
                      );
                      reject('Sign-in modal shown');
                    });
                  }
                  return isSelected ? removeFromBucketList(eventId) : addToBucketList(eventId);
                }}
              />
            </div>
          }
        </div>
      </AhotuLink>
    </>
  );
};

export default FeaturedCard;

function roundPrice(priceStr: string) {
  const num = parseFloat(priceStr.replace(/[^0-9.]/g, ''));
  const roundedNum = Math.ceil(num);
  return priceStr.replace(num.toString(), roundedNum.toString());
}
