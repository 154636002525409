import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { FeaturedEvent, FeaturedEventsPage } from '../../types/sportPageTypes';
import usePerfectWidth from '../../utils/usePerfectWidth';
import { Heading } from '../atoms/Heading';
import { sqbtnSize } from '../atoms/SquareButton';
import { FeaturedCard } from '../molecules/FeaturedCard';
import { SideScrollWithButtons } from '../molecules/side-scroll/SideScrollWithButtons';

export interface IFeaturedEventProps {
  events: FeaturedEvent[];
  className?: string;
  item?: JSX.Element;
  sideScrollClassName?: string;
  sideScrollControlColor?: 'lightgray' | 'gray' | 'white' | 'green';
  isRecommended?: boolean;
  controlButtonSize?: sqbtnSize;
  useOn?: FeaturedEventsPage;
  fromSection: string;
  titleKey: string;
  titleValues?: any;
}

const FeaturedEvents = ({
  events,
  className,
  item,
  sideScrollClassName,
  sideScrollControlColor,
  isRecommended = false,
  controlButtonSize,
  useOn,
  fromSection,
  titleKey,
  titleValues
}: IFeaturedEventProps) => {
  const [reasonablyRandomValue] = useState(() => Math.random().toString(36).substr(2, 9));

  const { t } = useTranslation();
  const width = usePerfectWidth(
    `${reasonablyRandomValue}-events[0]?.name`,
    `${reasonablyRandomValue}-events[1]?.name`,
    isRecommended ? 170 : 250,
    isRecommended ? 220 : 350,
    isRecommended ? 200 : 288,
    'featuredEvents'
  );

  return (
    <div id="featuredEvents" className={isRecommended ? 'border border-green-70 rounded' : ''}>
      <SideScrollWithButtons
        item={
          item ? (
            item
          ) : (
            <Heading headingLevel="h2" size="2xl" className="mr-4">
              {t(titleKey, titleValues)}
            </Heading>
          )
        }
        offset={width + 32}
        className={className}
        sideScrollClassName={sideScrollClassName}
        sideScrollControlColor={sideScrollControlColor}
        btnSize={controlButtonSize}>
        {events.map(event => (
          <FeaturedCard
            idPrefix={reasonablyRandomValue}
            width={width}
            key={event.name}
            event={event}
            isRecommended={isRecommended}
            useOn={useOn}
            fromSection={fromSection}
          />
        ))}
      </SideScrollWithButtons>
    </div>
  );
};

export default FeaturedEvents;
